import React from 'react'
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../includes/route-names';

function SubscriptionButton() {
    return (
        <Link to={`${RouteNames.member_ship}?_scrolled=true`} className="subscribe">
            <i className="fa fa-sign-in" aria-hidden="true"></i>subscribe
        </Link>
    )
}

export default SubscriptionButton;
