import React from 'react';

function StaticTablePart() {
    return (
        <tbody>
            <tr>
                <td>Min. duration needed to see optimized results of efforts</td>
                <td>3 months</td>
                <td>3 months</td>
                <td>3 months</td> 
            </tr>
            <tr>
                <td>No. of locations covered</td>
                <td>1</td>
                <td>3</td>
                <td>6</td> 
            </tr>
            {/*<tr>
                <td>3-months estimates</td>
                <td>897</td>
                <td>2097</td>
                <td>3897</td> 
            </tr>*/}
            <tr>
                <td>Profile / Page creation</td>
                <td>Facebook &  Instagram</td>
                <td>Facebook &  Instagram</td>
                <td>Facebook &  Instagram</td> 
            </tr>
            <tr>
                <td>Update cover images</td>
                <td>1 per month</td>
                <td>1 per month</td>
                <td>2 per month</td> 
            </tr>
            <tr>
                <td>Social media network</td>
                <td>Facebook &  Instagram</td>
                <td>Facebook &  Instagram</td>
                <td>Facebook &  Instagram</td> 
            </tr>
            <tr>
                <td>Social media posts</td>
                <td>2 per week</td>
                <td>4 per week</td>
                <td>8 per week</td> 
            </tr>
            <tr>
                <td>Special formats like stories, carousels</td>
                <td>-</td>
                <td>-</td>
                <td>1 per week</td> 
            </tr>
            <tr>
                <td>Comments monitoring</td>
                <td>Weekly</td>
                <td>Once in 3 days</td>
                <td>Every day</td> 
            </tr>
            <tr>
                <td>Google My Business</td>
                <td>1 location</td>
                <td>3 locations</td>
                <td>6 locations</td> 
            </tr>
            <tr>
                <td>Apple Maps</td>
                <td>1 location</td>
                <td>3 locations</td>
                <td>6 locations</td> 
            </tr>
            <tr>
                <td>Bing Maps</td>
                <td>1 location</td>
                <td>3 locations</td>
                <td>6 locations</td> 
            </tr>
            <tr>
                <td>NAP Syndication</td>
                <td>yes</td>
                <td>yes</td>
                <td>yes</td> 
            </tr>
            <tr>
                <td>hcard integration</td>
                <td>yes</td>
                <td>yes</td>
                <td>yes</td> 
            </tr>
            <tr>
                <td>citation / classified submissions </td>
                <td>5 per month</td>
                <td>15 per month</td>
                <td>30 per month</td> 
            </tr>
            <tr>
                <td>Images for promotions</td>
                <td>2 per month</td>
                <td>4 per month</td>
                <td>6 per month</td> 
            </tr>
            <tr>
                <td>Keywords</td>
                <td>10</td>
                <td>20</td>
                <td>30</td> 
            </tr>
            <tr>
                <td>Primary Keywords</td>
                <td>5</td>
                <td>10</td>
                <td>15</td> 
            </tr>
            <tr>
                <td>Secondary Keywords</td>
                <td>5</td>
                <td>10</td>
                <td>15</td> 
            </tr>
            <tr>
                <td>On-Page Optimization</td>
                <td>for 10 pages</td>
                <td>for 15 pages</td>
                <td>for 20 pages</td> 
            </tr>
            <tr>
                <td>Technical SEO</td>
                <td>-</td>
                <td>-</td>
                <td>-</td> 
            </tr>
            <tr>
                <td>Tracking Implementation</td>
                <td>Google Analytics via tag manager</td>
                <td>GA + Facebook Pixels + Twitter Tracking via tag manager</td>
                <td>GA + Facebook Pixels + Twitter Tracking via tag manager</td> 
            </tr>
            <tr>
                <td>Ad Networks</td>
                <td>-</td>
                <td>Google, Facebook & Instagram</td>
                <td>Google, Facebook & Instagram</td> 
            </tr>
            <tr>
                <td>Products advertised</td>
                <td>-</td>
                <td>-</td>
                <td>20</td> 
            </tr>
            <tr>
                <td>Email Newsletter</td>
                <td>-</td>
                <td>1</td>
                <td>1</td> 
            </tr>
            <tr>
                <td>Flyers</td>
                <td>-</td>
                <td>1</td>
                <td>1</td> 
            </tr>
            <tr>
                <td>Blogs</td>
                <td>-</td>
                <td>1</td>
                <td>2 per month</td> 
            </tr>
            <tr>
                <td>What to expect</td>
                <td>Better online visibility in your locality</td>
                <td>5-10 enquiries/calls along with best visibility in 3 locations</td>
                <td>10-25 enquiries/calls with best-in-class creatives</td> 
            </tr>
        </tbody>
    )
}

export default StaticTablePart;
