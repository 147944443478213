import Api from './api';

export const saveCustomerPayment = async (url, paymentData) => {
    try {

        const result = await (new Api()).submitWithParams(`${url}`, 'POST', paymentData);
        //console.log(result)
        if (result.status === 'ok') {
            return {
                error: false,
                data: result.data,
                content_id: result.content_id,

            }
        } else {
            return {
                error: true,
                data: [],
                msg: result.msg
            }
        }
    } catch (error) {

    }
}

export const getAllSubscriptions = async () => {
    try {

        const result = await (new Api()).submit('subscriptions/product');

        if (result.status === 'ok') {
            return {

                error: false,
                data: result.data,

            }
        } else {
            return {
                error: true,
                data: [],
                msg: result.msg
            }
        }
    } catch (error) {
        return {

            error: true,
            data: [],

        }
    }
}

export const createOrder = async (paymentData) => {
    try {

        const result = await (new Api()).submitWithParams(`order`, 'POST', paymentData);
        //console.log(result)
        if (result.status === 'ok') {
            return {
                error: false,
                data: result.data,

            }
        } else {
            return {
                error: true,
                data: [],
                msg: result.msg
            }
        }
    } catch (error) {

    }
}

export const getOrderDetails = async (id) => {
    try {

        const result = await (new Api()).submit(`view/order/${id}`);

        if (result.status === 'ok') {
            return {

                error: false,
                data: result.data,

            }
        } else {
            return {
                error: true,
                data: [],
                msg: result.msg
            }
        }
    } catch (error) {
        return {

            error: true,
            data: [],

        }
    }
}

export const getAllOrders = async (pageNo = 0) => {
    try {

        const result = await (new Api()).submit(`user/order?page=${pageNo}`);

        if (result.status === 'ok') {
            return {

                error: false,
                data: { data: result.data, pageData: result.pageData, seoData: result.seoData },

            }
        } else {
            return {
                error: true,
                data: [],
                msg: result.msg
            }
        }
    } catch (error) {
        return {

            error: true,
            data: [],

        }
    }
}

