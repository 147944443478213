import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Banner from '../../../includes/Banner';
import Footer from '../../../includes/Footer';
import Header from '../../../includes/Header';
import { RouteNames } from '../../../includes/route-names';
import { callLoading } from '../../../stores/actions/common-action';
import { getOrderDetails } from '../../../stores/actions/membership';

function OrderDetails() {

    const { orderId } = useParams();
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const [orderDate, setOrderDate] = useState(null);
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        dispatch(callLoading(true));
        getOrderDetails(orderId).then(resp => {
            dispatch(callLoading(false));
            if (!resp.error) {
                setOrders(resp.data ? resp.data : []);
                if (resp.data && resp.data.length > 0) {
                    setOrderDate(moment(resp.data[0].field_subscription_start_date).format('DD-MM-YYYY'))
                }
                setTotalCost(prevTotal => {
                    if (resp.data && resp.data.length > 0) {
                        prevTotal = resp.data.map(ord => parseFloat(ord.total_price));
                        prevTotal = prevTotal.reduce((old, newV) => old + newV, 0);
                        return prevTotal;
                    }
                    return prevTotal;
                });
                window.scrollTo(0, 0);
            }
        })
    }, [])

    return (
        <>
            <Header />
            <Banner
                bannerImage="mmbrbnr.jpg"
                pageName="Order Details"
                parentPageName="Order List"
                parentRoute={RouteNames.userOrders}
            />
            <main className="site-main inner-main ">
                <section className="revwadd-form-sec">
                    <div className="container">
                        <div className="order__topSection">
                            <span className="order__id"><b>Order ID: </b>{orderId}</span><br/>
                            <span className="order__date"><b>Order Date: </b>{orderDate ? orderDate : ''}</span>
                        </div>
                        <div className="order_details">
                            <h4 className="order__title">Order Details</h4>
                            <div className="table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Service Type</th>
                                            <th className="tdcntr">No. of Posts</th>
                                            <th>Unit Rate</th>
                                            <th className="tdcntr">Month(s)</th>
                                            <th>Subscription period</th>
                                            <th>Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orders && orders.length > 0 ?
                                                orders.map((order, index) =>
                                                    <tr key={index}>
                                                        <td>{order.field_product}</td>
                                                        <td className="tdcntr">{order.qty}</td>
                                                        <td>${order.unit_rate}</td>
                                                        <td className="tdcntr">{order.field_no_of_months}</td>
                                                        <td>{moment(order.field_subscription_start_date).format('DD-MM-YYYY')} - {moment(order.field_subscription_end_date).format('DD-MM-YYYY')}</td>
                                                        <td>${order.total_price}</td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className="memberCenter" colSpan={6}> No orders...</td>
                                                </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Total Cost:</td>
                                            <td>${totalCost.toFixed(2)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default OrderDetails;
