import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch, Router } from 'react-router-dom';
import { RouteNames } from '../includes/route-names';
import DigitalMarketingService from '../pages/digital-marketing-service/DigitalMarketingService';
import ErrorHandle from '../pages/ErrorHandle';
import Thankyou from '../pages/thankyou/Thankyou';
import OrderDetails from '../pages/user/my-orders/OrderDetails';
import OrderList from '../pages/user/my-orders/OrderList';
import { AuthenticateRoute } from './authenticate-route';
const Home = lazy(() => import('../pages/home/Home'));
const BuyList = lazy(() => import('../pages/buy/BuyList'));
const ServicesList = lazy(() => import('../pages/services/ServicesList'));
const MemberShip = lazy(() => import('../pages/member-ship/MemberShip'));
const SmartSolution = lazy(() => import('../pages/solutions/SmartSolution'));
const Compare = lazy(() => import('../pages/compare/compare'));
const BuyDetail = lazy(() => import('../pages/buy/BuyDetail'));
const ServicesDetail = lazy(() => import('../pages/services/ServicesDetail'));
const AboutUs = lazy(() => import('../pages/about/about-us'));
const ContactUs = lazy(() => import('../pages/contact-us/contact-us'));
const BikeDestination = lazy(() => import('../pages/bike-destination/BikeDestination'));
const EventList = lazy(() => import('../pages/events/EventList'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const BuyForm = lazy(() => import('../pages/buy/BuyForm'));
const ServicesForm = lazy(() => import('../pages/services/ServicesForm'));
const SmartSolutionForm = lazy(() => import('../pages/solutions/SmartSolutionForm'));
const DestinationForm = lazy(() => import('../pages/bike-destination/destination-form'));
const SmartSolutionDetail = lazy(() => import('../pages/solutions/SmartSolutionDetail'));
const MyProfile = lazy(() => import('../pages/user/my-profile'));
const Statistic = lazy(() => import('../pages/user/my-statistic/statistic'));
const City = lazy(() => import('../pages/bike-destination/details/city'));
const BikeEnthisticList = lazy(() => import('../pages/bike-enthusiasts/BikeEnthisticList'));
const BikeEnthisticForm = lazy(() => import('../pages/bike-enthusiasts/BikeEnthisticForm'));
const EventForm = lazy(() => import('../pages/events/EventForm'));
const EventDetail = lazy(() => import('../pages/events/EventDetail'));
const BikeEnthisticDetail = lazy(() => import('../pages/bike-enthusiasts/BikeEnthisticDetail'));
const List = lazy(() => import('../pages/user/my-list/list'));
const TermCondition = lazy(() => import('../pages/static-pages/TermCondition'));
const PrivacyPolicy = lazy(() => import('../pages/static-pages/PrivacyPolicy'));
const Partner = lazy(() => import('../pages/static-pages/Partner'));

const MainRoutes = (props) => {
	return (
		<Suspense fallback={<div />}>
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path={RouteNames.reset_password} exact component={ResetPassword} />
				<Route path={RouteNames.about} exact component={AboutUs} />
				<Route path={RouteNames.contactUs} exact component={ContactUs} />

				<Route path={RouteNames.buy} exact component={BuyList} />
				<Route path={`${RouteNames.buy_detail}/:alias?`} exact component={BuyDetail} />
				<AuthenticateRoute path={RouteNames.buy_form} exact component={BuyForm} />
				<AuthenticateRoute path={`${RouteNames.buy_form_preview}/:alias?`} exact component={BuyDetail} />

				<Route path={RouteNames.services} exact component={ServicesList} />
				<Route path={`${RouteNames.servicesDetail}/:alias?`} exact component={ServicesDetail} />
				<AuthenticateRoute path={RouteNames.serviceForm} exact component={ServicesForm} />
				<AuthenticateRoute path={`${RouteNames.serviceFromPreview}/:alias?`} exact component={ServicesDetail} />
				<Route path={RouteNames.event} exact component={EventList} />
				<Route path={`${RouteNames.eventDetails}/:alias?`} exact component={EventDetail} />
				<AuthenticateRoute path={`${RouteNames.eventForm_preview}/:alias?`} exact component={EventDetail} />
				<AuthenticateRoute path={RouteNames.eventForm} exact component={EventForm} />

				<Route path={RouteNames.smart_solution} exact component={SmartSolution} />
				<Route path={`${RouteNames.smart_solution_detail}/:alias?`} exact component={SmartSolutionDetail} />
				<AuthenticateRoute path={RouteNames.smart_solution_form} exact component={SmartSolutionForm} />
				<AuthenticateRoute path={`${RouteNames.smart_solution_form_preview}/:alias?`} exact component={SmartSolutionDetail} />

				<Route path={`${RouteNames.biking}/:country?`} exact component={BikeDestination} />
				<Route path={`${RouteNames.bikingDestinationDetails}/:city`} exact component={City} />
				<AuthenticateRoute path={`${RouteNames.destinationFormPreview}/:city`} exact component={City} />
				<AuthenticateRoute path={RouteNames.destinationForm} exact component={DestinationForm} />

				<Route path={RouteNames.bikeEnthusticList} exact component={BikeEnthisticList} />
				<Route path={`${RouteNames.bikeEnthusticDetails}/:alias?`} exact component={BikeEnthisticDetail} />
				<AuthenticateRoute path={`${RouteNames.bikeEnthusticFormPreview}/:alias?`} exact component={BikeEnthisticDetail} />
				<AuthenticateRoute path={RouteNames.bikeEnthusticForm} exact component={BikeEnthisticForm} />

				<Route path={RouteNames.member_ship} exact component={MemberShip} />

				<Route path={RouteNames.compare} exact component={Compare} />

				<AuthenticateRoute path={RouteNames.myprofile} exact component={MyProfile} />
				<AuthenticateRoute path={RouteNames.myStatistic} exact component={Statistic} />
				<AuthenticateRoute path={`${RouteNames.userList}/:listType`} exact component={List} />
				<Route path={RouteNames.termsAndCondition} exact component={TermCondition} />
				<Route path={RouteNames.privacyPolicy} exact component={PrivacyPolicy} />
				<Route path={RouteNames.partners} exact component={Partner} />
				<AuthenticateRoute path={`${RouteNames.thankyou}/:orderId`} exact component={Thankyou} />

				<AuthenticateRoute path={RouteNames.userOrders} exact component={OrderList} />
				<AuthenticateRoute path={`${RouteNames.userOrderDetails}/:orderId`} exact component={OrderDetails} />

				<Route path={RouteNames.digitalMarketingService} exact component={DigitalMarketingService}/>
				<Redirect to='/' />
				{/* <Route component={ErrorHandle} /> */}
			</Switch>
		</Suspense>
	)
}

export default MainRoutes;