import React from 'react';
import { Route, Redirect } from 'react-router-dom';  
import { ToastContainer, } from 'react-toastify';

export const AuthenticateRoute =({ component: Component, ...rest })=>{
    const isloggedin = localStorage.getItem('csrf_token');
    return (
        <Route
          {...rest}
          render={(props) =>{
            if(isloggedin){
              return (
                <React.Fragment>
                  <Component {...props}/>
                  <div>
                      {/* <ToastContainer /> */}
                    </div>
                </React.Fragment>
              )
            }else{
              return <Redirect to="/"/>
            }
          }
        }
        /> 
      );
}