import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Banner from '../../../includes/Banner';
import Footer from '../../../includes/Footer';
import Header from '../../../includes/Header';
import { callLoading } from '../../../stores/actions/common-action';
import { getAllOrders } from '../../../stores/actions/membership';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../includes/route-names';
import Pagination from '../../../reuseables/Pagination';

function OrderList() {
    const [activePage, setActivePage] = useState(1);
    const [ordersList, setOrdersList] = useState([]);
    const [orderData, setOrderData] = useState({});
    const dispatch = useDispatch();

    const _getOrderList = (page) => {
        dispatch(callLoading(true));
        getAllOrders(page).then(resp => {
            dispatch(callLoading(false));

            if (!resp.error && resp.data && resp.data.pageData) {
                const { data } = resp.data;
                setOrdersList(data);
                if (resp.data.pageData) {
                    //console.log(resp.data);
                    setOrderData(resp.data);
                    const { currentPage } = resp.data.pageData;
                    setActivePage(currentPage + 1);
                }
                window.scrollTo(0, 150);
            }
        })
    }

    useEffect(() => {
        _getOrderList(0);
    }, []);

    const pageChangeHandler = (page) => {
        _getOrderList(page - 1);
    }

    return (
        <>
            <Header />
            <Banner bannerImage="mmbrbnr.jpg" pageName="Order List"></Banner>

            <main className="site-main inner-main ">
                <section className="revwadd-form-sec">
                    <div className="container">
                        <div className="user__order__list">
                            <div className="table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>Created Date</th>
                                            <th>Total Amount</th>
                                            <th>Order Status</th>
                                            <th className="tdcntr">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ordersList && ordersList.length > 0 ?
                                                ordersList.map((order, index) =>
                                                    <tr key={index}>
                                                        <td>{order.order_id}</td>
                                                        <td>{moment(order.created).format('DD-MM-YYYY')}</td>
                                                        <td>${order.field_order_total}</td>
                                                        <td>{order.status}</td>
                                                        <td className="tdcntr">
                                                            <Link to={`${RouteNames.userOrderDetails}/${order.order_id}`}><i className="fa fa-eye" aria-hidden="true"></i></Link>
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className="memberCenter" colSpan={5}> No orders...</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <Pagination
                            activePage={activePage}
                            pageData={orderData && orderData.hasOwnProperty('pageData') ? orderData.pageData : null}
                            onPageChange={pageChangeHandler}
                        />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default OrderList;
