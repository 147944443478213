// import React from 'react'
import React, { useEffect } from 'react';
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import StaticTablePart from './components/StaticTablePart';
import SubscriptionButton from './components/SubscriptionButton';

function DigitalMarketingService() {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);

    return (
        <>
            <Header/>
            <div className="banner innerbanner membership-banner">
                <img src={require('../../assets/images/mmbrbnr.jpg')} alt="blog" />
            </div>
            {
            	 <div className="membershipPlans">
                    <div className="container-fluid">
                        <div className="planDtlsdigital-table">
                            <div className="table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <div className="top">
                                                    <h4>agile</h4>
                                                    <h2>$649</h2>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="top">
                                                    <h4>trendsetter</h4>
                                                    <h2>$1349</h2>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="top">
                                                    <h4>pioneer</h4>
                                                    <h2>$2249</h2>
                                                </div>
                                            </th> 
                                        </tr>
                                    </thead>
                                    <StaticTablePart/>
                                    <tfoot>
                                    <tr>
                                        <td> </td>
                                        <td><SubscriptionButton/></td>
                                        <td><SubscriptionButton/></td>
                                        <td><SubscriptionButton/></td> 
                                    </tr>
                                    </tfoot>
                                </table>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer/>
        </>
    )
}

export default DigitalMarketingService;
